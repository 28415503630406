import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import FindOurProductsSection from 'components/FindOurProductsSection';

const FindOurProductsPage: FC<FindOurProductsPageTypes.FindOurProductsPageProps> = ({
  data: {
    findOurProductsPage: { urls, seo, topBanner, singleLineBanner, findOurProductsSection },
  },
}) => (
  <Layout headerTransparent seo={seo} urls={urls}>
    <FindOurProductsSection
      topBanner={topBanner}
      findOurProducts={findOurProductsSection}
      singleLineBanner={singleLineBanner}
    />
  </Layout>
);

export const query = graphql`
  query ($lang: String) {
    findOurProductsPage(lang: { eq: $lang }) {
      urls {
        ...UrlsFragment
      }
      seo {
        ...SEOStructureFragment
      }
      singleLineBanner {
        ...SingleLineBannerFragment
      }
      topBanner {
        ...TopBannerFragment
      }
      findOurProductsSection {
        ...FindOurProductsSectionFragment
      }
      singleLineBanner {
        ...SingleLineBannerFragment
      }
    }
  }
`;

export default FindOurProductsPage;
