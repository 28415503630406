import React, { FC } from 'react';

import FindOurProductsPlace from 'components/FindOurProductsPlace';
import SingleLineBanner from 'components/SingleLineBanner';
import TopBanner from 'components/TopBanner';

import { FindOurProductsSectionProps } from './models';

import './FindOurProductsSection.scss';

const FindOurProductsSection: FC<FindOurProductsSectionProps> = ({
  topBanner,
  singleLineBanner,
  findOurProducts: { online, offline },
}) => (
  <>
    <TopBanner {...{ topBanner }} />
    {offline || online ? (
      <section className="find-our-products-section__main-wrapper">
        {offline?.length ? (
          <div className="find-our-products-section__wrapper">
            <div className="find-our-products-section" role="listbox">
              {offline.map((place) =>
                place?.link ? (
                  <a
                    key={place?.image?.altText}
                    href={place?.link.url}
                    aria-label={place?.link?.name}
                    rel="nofollow noopener"
                  >
                    <FindOurProductsPlace place={place} />
                  </a>
                ) : (
                  <FindOurProductsPlace place={place} key={place?.image?.altText} />
                )
              )}
            </div>
          </div>
        ) : null}
        {online?.length ? (
          <div className="find-our-products-section__wrapper">
            <div className="find-our-products-section" role="list">
              {online.map((place) =>
                place?.link ? (
                  <a
                    key={place?.image?.altText}
                    href={place?.link.url}
                    aria-label={place?.link?.name}
                    rel="nofollow noopener"
                  >
                    <FindOurProductsPlace place={place} />
                  </a>
                ) : (
                  <FindOurProductsPlace place={place} key={place?.image?.altText} />
                )
              )}
            </div>
          </div>
        ) : null}
      </section>
    ) : null}
    <SingleLineBanner {...{ singleLineBanner }} />
  </>
);

export default FindOurProductsSection;
