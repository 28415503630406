import React, { FC } from 'react';

import SvgImageIcon from 'common/SvgImageIcon';

import { FindOurProductsPlaceProps } from './models';

import './FindOurProductsPlace.scss';

const FindOurProductsPlace: FC<FindOurProductsPlaceProps> = ({ place }) => {
  const nameWithHardHyphens = place?.name?.replace('-', '-\u2060');

  return (
    <div className="find-our-products-place" role="listitem">
      <div className="find-our-products-place__container">
        <SvgImageIcon
          icon={place?.image}
          iconAlt={place?.image?.altText || place?.name || 'logo image'}
          customClassName="find-our-products-place__image"
        />
        {nameWithHardHyphens ? (
          <h2 className="find-our-products-place__name">{nameWithHardHyphens}</h2>
        ) : null}
      </div>
    </div>
  );
};

export default FindOurProductsPlace;
